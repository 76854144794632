import styled from 'styled-components'
import { Layout } from 'antd'
import { colors } from 'Common/constants'

export const StyledHeader = styled(Layout.Header)`
  background-color: ${colors.white};
  height: 120px;
  padding: 0 8px;
  position: fixed;
  width: 100%;
  z-index: 99;
`
export const HeaderInnerWrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: auto 1fr;
  align-items: center;
  line-height: 65px;
`
export const AppLogo = styled.img`
  width: 48px;
`
export const HeaderInfo = styled.div`
  line-height: 1;
`
export const Title = styled.h3`
  color: ${colors.main};
  font-weight: 500;
`
export const StoreLabel = styled.span`
  color: ${colors.grey2};
`
