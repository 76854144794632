import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'
import { BreadcrumbItem } from './styled'
const CustomBreadcrumb = props => {
  const { items } = props
  return (
    <Breadcrumb separator="|" style={{ lineHeight: 2 }}>
      <BreadcrumbItem>
        <Link to="/">
          <FormattedMessage id={'breadcrumb.item.home'} />
        </Link>
      </BreadcrumbItem>
      {items.map((item, index) => {
        const { description, id } = item
        return (
          <BreadcrumbItem key={id}>
            {index === items.length - 1 ? (
              description
            ) : (
              <Link
                to={{
                  pathname: `/${id}`,
                  state: { itemId: id },
                }}>
                {description}
              </Link>
            )}
          </BreadcrumbItem>
        )
      })}
    </Breadcrumb>
  )
}
CustomBreadcrumb.propTypes = {
  items: PropTypes.array,
}
export default CustomBreadcrumb
