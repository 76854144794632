import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, withRouter } from 'react-router-dom'
import { Layout } from 'antd'
import { connect } from 'react-redux'

import { initStore, getStores } from 'Redux/actions/app-actions'
import { saveAnalytics } from 'Redux/actions/analytics-actions'
import {
  getSearchSuggest,
  resetSearchSuggest,
} from 'Redux/actions/data-actions'

import Home from 'Containers/Home'
import Grid from 'Containers/Grid'
import Search from 'Containers/Search'
import Header from 'Components/Header'
import StoreModal from 'Components/StoreModal'
import Footer from 'Components/Footer'
import { PaddedContent } from 'Common/styled'

import { getQueryParam } from 'Common/utils'
import { TYPE_CATEGORY, TYPE_PRODUCT } from 'Common/constants'

class App extends Component {
  static propTypes = {
    saveAnalytics: PropTypes.func,
    searchSuggest: PropTypes.arrayOf(PropTypes.object),
    searchProducts: PropTypes.bool,
    stores: PropTypes.arrayOf(PropTypes.object),
    store: PropTypes.object,
  }

  state = {
    isStoreModalVisible: false,
  }

  async componentDidMount() {
    const { store, getStores, initStore, searchSuggest, resetSearchSuggest } =
      this.props

    const newStoreId = store.id || getQueryParam('storeId')
    const stores = await getStores()
    const existingStore = stores.find(s => s.id === newStoreId)

    newStoreId && existingStore
      ? initStore(newStoreId)
      : this.onOpenStoreModal()

    if (searchSuggest.length > 0) resetSearchSuggest()
  }

  onOpenStoreModal = () => this.setState({ isStoreModalVisible: true })
  onCloseStoreModal = () => this.setState({ isStoreModalVisible: false })

  onStoreSelect = storeId => {
    const { initStore } = this.props
    this.onCloseStoreModal()
    initStore(storeId)
  }
  onSearchSelect = (text, itemId, itemDescription, type) => {
    const { history, saveAnalytics, resetSearchSuggest } = this.props

    if (itemId === 'searchProducts') {
      // Analytics event product search
      saveAnalytics({
        action: 'search',
        type: TYPE_PRODUCT,
        subType: 'opus',
        fullText: text,
      })
      // Navigate to products search
      history.push('/search', { searchText: text })
    } else {
      resetSearchSuggest()
      // Navigate to selected item
      history.push(`/${itemId}`, { itemId, isPositionModalVisible: true })
      // Analytics event category search
      saveAnalytics({
        action: 'search',
        type: TYPE_CATEGORY,
        subType: type,
        subject: itemId,
        fullText: text,
      })
    }
  }
  onSearch = searchText => {
    const { getSearchSuggest, resetSearchSuggest } = this.props
    searchText.length >= 3 ? getSearchSuggest(searchText) : resetSearchSuggest()
  }

  render() {
    const { isStoreModalVisible } = this.state
    const { stores, store, searchSuggest, searchProducts } = this.props
    return (
      <Layout>
        {Header({
          storeLabel: store.label,
          searchSuggest,
          searchProducts,
          onSelectCallback: this.onSearchSelect,
          onSearchCallback: this.onSearch,
          onChangeStoreCallback: this.onOpenStoreModal,
        })}
        <PaddedContent>
          <Switch>
            <Route component={Home} exact path={'/'} />
            <Route component={Search} path={'/search'} />
            <Route component={Grid} path={'/:categoryId'} />
          </Switch>
        </PaddedContent>
        <StoreModal
          storeId={store.id}
          stores={stores}
          isVisible={isStoreModalVisible}
          onSelectCallback={this.onStoreSelect}
          onCloseCallback={this.onCloseStoreModal}
        />
        {Footer({ storeId: store.id })}
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  stores: state.app.stores,
  store: state.app.store,
  searchSuggest: state.data.suggest,
  searchProducts: state.data.searchProducts,
})
const mapDispatchToProps = {
  getStores,
  initStore,
  saveAnalytics,
  getSearchSuggest,
  resetSearchSuggest,
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
