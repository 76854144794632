import React from 'react'
import { FormattedMessage } from 'react-intl'
import Pin from 'Components/Pin'
import { SubTitle } from 'Common/styled'
const Abstract = () => (
  <div>
    <SubTitle>
      <FormattedMessage id={'abstract.text1'} />
    </SubTitle>
    <SubTitle>
      <FormattedMessage
        id={'abstract.text2'}
        values={{ pinIcon: Pin({ width: 15, style: { marginTop: -10 } }) }}
      />
    </SubTitle>
  </div>
)
export default Abstract
