import * as actionTypes from '../action-types'
import store from 'Redux/store'
import api from 'Common/api'
import { handleServerError } from 'Common/utils'

export const setLoading = loading => {
  return { type: actionTypes.SET_LOADING, payload: loading }
}
export const initStore = id => async dispatch => {
  const { stores } = store.getState().app
  const selectedStore = {
    id,
    label: stores.find(s => s.id === id).label,
  }
  return await dispatch({
    type: actionTypes.INIT_STORE,
    payload: selectedStore,
  })
}
export const getStores = () => async dispatch => {
  try {
    const res = await api.stores.list()
    const stores = res.data
      .map(s => ({ id: s.id, label: s.publicName }))
      .sort((a, b) => a.label.localeCompare(b.label))
    dispatch({ type: actionTypes.GET_STORES, payload: stores })
    return stores
  } catch (error) {
    return handleServerError(error)
  }
}
