import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'antd'
import { connect } from 'react-redux'

import { updateUuid, saveAnalytics } from 'Redux/actions/analytics-actions'
import { getCategoryChildren } from 'Redux/actions/data-actions'

import Loader from 'Components/Loader'
import Abstract from 'Components/Abstract'
import CardGrid from 'Components/CardGrid'
import PositionModal from 'Components/PositionModal'
import { StyledRow } from 'Common/styled'

import { TYPE_CATEGORY } from 'Common/constants'

class Home extends Component {
  static propTypes = {
    getCategoryChildren: PropTypes.func,
    items: PropTypes.array,
    saveAnalytics: PropTypes.func,
    storeId: PropTypes.string,
    updateUuid: PropTypes.func,
    uuid: PropTypes.string,
  }
  state = {
    isLoading: true,
    isPositionModalVisible: false,
  }
  async componentDidMount () {
    const { uuid, storeId, updateUuid, getCategoryChildren } = this.props
    // Init store and fetch data
    if (storeId) {
      await getCategoryChildren(0)
      this.setState({ isLoading: false })
    }
    // Init uuid for analytics
    if (!uuid) updateUuid()
  }
  async componentDidUpdate (prevProps, prevState) {
    const { storeId, getCategoryChildren } = this.props

    if (prevProps.storeId !== storeId) {
      await getCategoryChildren(0)
      this.setState({ isLoading: false })
    }
  }
  onOpenModal = item => {
    const { saveAnalytics } = this.props

    this.setState({ isPositionModalVisible: true, modalItem: item })
    // Analytics event open modal
    saveAnalytics({
      action: 'open_modal',
      type: TYPE_CATEGORY,
      subType: 'browse',
      subject: item.id,
    })
  }
  onCloseModal = () => {
    this.setState({ isPositionModalVisible: false })
  }
  render () {
    const { isLoading, isPositionModalVisible, modalItem } = this.state
    const { items } = this.props
    return (
      <>
        <StyledRow gutter={[16, 20]}>
          <Col span={24}>{Abstract()}</Col>
          {isLoading
            ? Loader()
            : CardGrid({
              items,
              isHome: true,
              openModalCallback: this.onOpenModal,
            })}
        </StyledRow>
        <PositionModal
          item={modalItem}
          isVisible={isPositionModalVisible}
          isPageLoading={isLoading}
          onCancelCallback={this.onCloseModal}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  uuid: state.app.uuid,
  storeId: state.app.store.id,
  items: state.data.children,
})
const mapDispatchToProps = {
  updateUuid,
  saveAnalytics,
  getCategoryChildren,
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
