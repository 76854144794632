import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Pin from 'Components/Pin'
import {
  StyledPositionBox,
  PositionCard,
  PositionDescription,
  PositionDepartment,
  PositionRow,
  PositionShowroom,
} from './styled'
import { ModalTitle } from 'Common/styled'
const PositionBox = props => {
  const { description, position } = props.item
  return (
    <StyledPositionBox>
      {Pin({})}
      <ModalTitle>
        <FormattedMessage id={'positionBox.title'} />
      </ModalTitle>
      <PositionCard>
        <PositionDescription>{description}</PositionDescription>
        {position?.department ? (
          <PositionDepartment hasZones={position.zones ? true : false}>
            <FormattedMessage id={'positionBox.title.store'} />
            <div>
              <span className="department">
                <FormattedMessage id={'positionBox.label.department'} /> <br />
                {position.department.name}
              </span>
              {position.rows.length > 0 ? (
                <span className="rows">
                  {position.rows.length > 1 ? (
                    <FormattedMessage id={'positionBox.label.rows.multiple'} />
                  ) : (
                    <FormattedMessage id={'positionBox.label.rows.single'} />
                  )}
                  <br />
                  {position.rows.map(row => (
                    <PositionRow key={row}>{row}</PositionRow>
                  ))}
                </span>
              ) : null}
              {position.zones?.length > 0 ? (
                <span className="zones">
                  <FormattedMessage id={'positionBox.label.zones'} /> <br />
                  <PositionRow>{position.zones[0]}</PositionRow>
                </span>
              ) : null}
            </div>
          </PositionDepartment>
        ) : null}
        {position?.showroom ? (
          <PositionShowroom>
            {position.department ? (
              <FormattedMessage
                id={'positionBox.title.showroom.hasDepartment'}
              />
            ) : (
              <FormattedMessage
                id={'positionBox.title.showroom.hasNoDepartment'}
              />
            )}
            <span>
              <FormattedMessage id={'positionBox.label.showroom'} />
            </span>
          </PositionShowroom>
        ) : null}
      </PositionCard>
    </StyledPositionBox>
  )
}
PositionBox.propTypes = {
  item: PropTypes.object,
}
export default PositionBox
