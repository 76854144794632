export const SET_LOADING = 'SET_LOADING'
export const INIT_STORE = 'INIT_STORE'
export const GET_STORES = 'GET_STORES'

export const SEARCH_EVENT = 'SEARCH_EVENT'
export const OPEN_MODAL_EVENT = 'OPEN_MODAL_EVENT'
export const VIEW_ITEM_LIST_EVENT = 'VIEW_ITEM_LIST_EVENT'

// API v2
export const GET_CATEGORY_CHILDREN = 'GET_CATEGORY_CHILDREN'
export const GET_CATEGORY_BREADCRUMB = 'GET_CATEGORY_BREADCRUMB'
export const GET_CATEGORY_POSITION = 'GET_CATEGORY_POSITION'
export const GET_CATEGORY_PRODUCTS = 'GET_CATEGORY_PRODUCTS'
export const GET_SEARCH_SUGGEST = 'GET_SEARCH_SUGGEST'
export const RESET_SEARCH_SUGGEST = 'RESET_SEARCH_SUGGEST'
export const GET_SEARCH_PRODUCTS = 'GET_SEARCH_PRODUCTS'

export const UPDATE_UUID = 'UPDATE_UUID'
export const SAVE_ANALYTICS = 'SAVE_ANALYTICS'