import React from 'react'
import PropTypes from 'prop-types'
const Pin = props => (
  <img
    style={props.style}
    className="pin"
    src="/assets/img/pin.png"
    alt="pin"
    width={props.width || 30}
    onClick={props.onClick}
  />
)
Pin.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
  width: PropTypes.number
}
export default Pin
