import styled from 'styled-components'
import { Layout, Row, Modal } from 'antd'
import { colors } from './constants'
export const PaddedContent = styled(Layout.Content)`
  padding: 140px 0 20px;
  min-height: calc(100vh - 32px);
`
export const Title = styled.h1`
  font-size: 35px;
  font-weight: bold;
  color: ${colors.main};
  text-align: center;
  margin: 0;
`
export const SubTitle = styled.h3`
  font-size: 16px;
  font-weight: normal;
  color: ${colors.blue};
  text-align: center;
`
export const StyledRow = styled(Row)`
  margin: -10px 0 !important;
`
export const StyledModal = styled(Modal)`
  color: ${colors.grey2};
  max-width: calc(100vw - 40px) !important;
  margin: 20px auto !important;
  & .ant-modal-close {
    background-color: ${colors.blue};
    color: ${colors.white};
    border-radius: 50%;
    top: -15px;
    right: -15px;
    width: 40px;
    height: 40px;
    &:hover {
      color: ${colors.white};
    }
    & > .ant-modal-close-x {
      font-size: 20px;
      line-height: 40px;
      width: inherit;
      height: inherit;
    }
  }
`
export const ModalTitle = styled.p`
  font-size: 24px;
  font-weight: lighter;
`
