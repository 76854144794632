import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Row, Col, Icon } from 'antd'

import SearchBar from 'Components/SearchBar'
import {
  StyledHeader,
  HeaderInnerWrapper,
  AppLogo,
  HeaderInfo,
  StoreLabel,
  Title,
} from './styled'
import { Link } from 'react-router-dom'

const Header = props => {
  const {
    storeLabel,
    searchSuggest,
    searchProducts,
    onSearchCallback,
    onSelectCallback,
    onChangeStoreCallback,
  } = props
  return (
    <StyledHeader>
      <Row gutter={30}>
        <Col span={24}>
          <HeaderInnerWrapper>
            <Link to="/">
              <AppLogo src="/assets/img/LM-logo.png" />
            </Link>
            <HeaderInfo>
              <Title>
                <FormattedMessage id={'header.title'} />
              </Title>
              <StoreLabel onClick={onChangeStoreCallback}>
                {storeLabel} {storeLabel && <Icon type="caret-down" />}
              </StoreLabel>
            </HeaderInfo>
          </HeaderInnerWrapper>
          <SearchBar
            onSelectCallback={onSelectCallback}
            onSearchCallback={onSearchCallback}
            suggest={searchSuggest}
            searchProducts={searchProducts}
          />
        </Col>
      </Row>
    </StyledHeader>
  )
}
Header.propTypes = {
  onSearchCallback: PropTypes.func,
  onSelectCallback: PropTypes.func,
  onChangeStoreCallback: PropTypes.func,
  searchSuggest: PropTypes.array,
  searchProducts: PropTypes.bool,
  storeLabel: PropTypes.string,
}
export default Header
