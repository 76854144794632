import React from 'react'
import PropTypes from 'prop-types'
import Loader from 'Components/Loader'
import PositionBox from 'Components/PositionBox'
import { StyledModal } from 'Common/styled'
const PositionModal = props => {
  const { isPageLoading, isVisible, item, onCancelCallback } = props
  return (
    <StyledModal
      centered
      visible={isVisible}
      footer={null}
      onCancel={onCancelCallback}>
      {isPageLoading ? Loader() : item && PositionBox({ item })}
    </StyledModal>
  )
}
PositionModal.propTypes = {
  isVisible: PropTypes.bool,
  item: PropTypes.object,
  onCancelCallback: PropTypes.func
}
export default PositionModal
