import API from './axiosConfig'
import { BU } from './constants'

export default {
  stores: {
    list: () =>
      API.get('/stores', { params: { bu: BU, application: 'customer' } }),
  },
  analytics: {
    save: body => API.post('/analytics', body),
  },
  category: {
    children: (storeId, categoryId) =>
      API.get(`/category/${categoryId}/children`, {
        params: { bu: BU, storeId },
      }),
    breadcrumb: (storeId, categoryId) =>
      API.get(`/category/${categoryId}/breadcrumb`, {
        params: { bu: BU, storeId },
      }),
    position: (storeId, categoryId) =>
      API.get(`/category/${categoryId}/position`, {
        params: { bu: BU, storeId },
      }),
    products: (storeId, categoryId, currentPage) =>
      API.get(`/category/${categoryId}/products`, {
        params: { bu: BU, storeId, currentPage },
      }),
  },
  search: {
    suggest: (storeId, fullText) =>
      API.get('/search/suggest', { params: { bu: BU, storeId, fullText } }),
    products: (storeId, fullText, currentPage) =>
      API.get('/search/products', {
        params: { bu: BU, storeId, fullText, currentPage },
      }),
  },
}
