import * as actionTypes from '../action-types'

export const initialState = {
  isLoading: true,
  stores: [],
  store: {},
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_LOADING:
      return { ...state, isLoading: payload }
    case actionTypes.GET_STORES:
      return { ...state, stores: payload }
    case actionTypes.INIT_STORE:
      return { ...state, store: payload }
    case actionTypes.UPDATE_UUID:
      return { ...state, uuid: payload }
    default:
      return state
  }
}

export default reducer
