import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Select } from 'antd'
// import Loader from 'Components/Loader'
import { StyledModal, ModalTitle } from 'Common/styled'

const StoreModal = props => {
  const {
    intl,
    storeId,
    stores,
    isVisible,
    onSelectCallback,
    onCloseCallback,
  } = props
  const isClosable = storeId ? true : false
  return (
    <StyledModal
      centered
      visible={isVisible}
      footer={null}
      closable={isClosable}
      maskClosable={isClosable}
      onCancel={onCloseCallback}>
      <ModalTitle>
        <FormattedMessage id={'storeModal.title'} />
      </ModalTitle>
      <Select
        onSelect={onSelectCallback}
        value={storeId}
        showSearch
        filterOption={(input, option) =>
          option.props.children.toLowerCase().includes(input.toLowerCase())
        }
        size="large"
        placeholder={intl.formatMessage({ id: 'storeModal.placeholder' })}
        style={{ width: '100%' }}>
        {stores.map(({ id, label }) => (
          <Select.Option key={id}>{label}</Select.Option>
        ))}
      </Select>
    </StyledModal>
  )
}
StoreModal.propTypes = {
  isVisible: PropTypes.bool,
  onCloseCallback: PropTypes.func,
  onSelectCallback: PropTypes.func,
  storeId: PropTypes.string,
}
export default injectIntl(StoreModal)
