import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { equals } from 'ramda'
import { FormattedMessage } from 'react-intl'
import { Col } from 'antd'
import { connect } from 'react-redux'

import { saveAnalytics } from 'Redux/actions/analytics-actions'
import { getSearchProducts } from 'Redux/actions/data-actions'

import Loader from 'Components/Loader'
import CardGrid from 'Components/CardGrid'
import CustomPagination from 'Components/Pagination'
import PositionModal from 'Components/PositionModal'
import { StyledRow, SubTitle } from 'Common/styled'

import { TYPE_PRODUCT } from 'Common/constants'

class Search extends Component {
  static propTypes = {
    currentPage: PropTypes.number,
    getSearchProducts: PropTypes.func,
    items: PropTypes.array,
    pageSize: PropTypes.number,
    storeId: PropTypes.string,
    totalProducts: PropTypes.number,
  }
  state = {
    isLoading: true,
    isModalVisible: false,
  }
  componentDidMount () {
    const { location, history, storeId } = this.props
    // Set location state
    if (location.state && storeId) {
      this.setState(location.state, () => {
        this.getSearchProducts()
      })
    } else {
      history.push('/')
    }
  }
  componentDidUpdate (prevProps) {
    const { location } = this.props
    if (!equals(prevProps.location.state, location.state)) {
      this.setState(location.state, () => {
        this.getSearchProducts()
      })
    }
  }
  getSearchProducts = async pageNumber => {
    const { getSearchProducts } = this.props
    this.setState({ isLoading: true })
    await getSearchProducts(this.state.searchText, pageNumber)
    this.setState({ isLoading: false })
  }

  onOpenModal = item => {
    const { saveAnalytics } = this.props
    this.setState({ isModalVisible: true, modalItem: item })
    // Analytics event open modal
    saveAnalytics({
      action: 'open_modal',
      type: TYPE_PRODUCT,
      subType: 'search',
      subject: item.id,
    })
  }
  onCloseModal = () => {
    this.setState({ isModalVisible: false })
  }
  render () {
    const { isLoading, isModalVisible, modalItem, searchText } = this.state
    const { items, total, limit, offset } = this.props
    return (
      <>
        <StyledRow gutter={[16, 20]}>
          <Col span={24}>
            <SubTitle>
              <FormattedMessage id={'search.title'} values={{ searchText }} />
            </SubTitle>
          </Col>
          {isLoading ? (
            Loader()
          ) : (
            <>
              {CardGrid({
                items,
                depth: 3,
                openModalCallback: this.onOpenModal,
              })}
              <Col span={24}>
                {CustomPagination({
                  totalProducts: total,
                  pageSize: limit,
                  currentPage: offset / limit + 1,
                  onChangeCallback: this.getSearchProducts,
                })}
              </Col>
            </>
          )}
        </StyledRow>
        <PositionModal
          item={modalItem}
          isVisible={isModalVisible}
          isPageLoading={isLoading}
          onCancelCallback={this.onCloseModal}
        />
      </>
    )
  }
}
const mapStateToProps = state => ({
  storeId: state.app.store.id,
  items: state.data.children,
  total: state.data.total,
  limit: state.data.limit,
  offset: state.data.offset,
})
const mapDispatchToProps = {
  saveAnalytics,
  getSearchProducts,
}
export default connect(mapStateToProps, mapDispatchToProps)(Search)
