import React from 'react'
import PropTypes from 'prop-types'
import { Col } from 'antd'
import Card from 'Components/Card'

const CardGrid = props => {
  const { items, isHome, depth, openModalCallback } = props
  return items.map((item, itemIndex) => (
    <Col key={item.id} span={12}>
      <Card
        item={item}
        isHome={isHome}
        depth={depth}
        openModalCallback={openModalCallback}
      />
    </Col>
  ))
}
CardGrid.propTypes = {
  depth: PropTypes.number,
  isHome: PropTypes.bool,
  items: PropTypes.array,
  openModalCallback: PropTypes.func,
}
export default CardGrid
